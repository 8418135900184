body {
  background-color: rgb(234, 234, 234) !important;
}

.ui.inverted.top.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(0, 168, 255) 69%,
    rgb(32, 219, 219) 89%
  ) !important;
}

.logoImg {
  padding: 10px 20px;
}

.homeLogoImg {
  margin-top: 5%;
}

.itemHeader {
  padding-top: 10px;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(0, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
  height: 100vh;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/*end home page styles*/

.myFont {
  font-family: "Kumbh Sans", sans-serif;
}

.loginContainer {
  margin: 10px;
  padding: 10px;
}

#supportEmailLink {
  font-size: 1.5rem;
  color: white;
}

#supportEmailLink:hover {
  color: blue;
}
